import React, {
  useEffect,
  useRef,
  useState,
  MutableRefObject,
  SetStateAction,
  Dispatch,
} from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from "@naturacosmeticos/natds-web";
import { I18n } from "src/I18n/I18n";

export interface IPasswordExpiredModalProps {
  visible?: boolean;
  // eslint-disable-next-line no-unused-vars
  onRedirect: () => void;
  i18n: I18n;
}

const timerFunction = async (
  timerId: MutableRefObject<NodeJS.Timeout | null>,
  setTimer: Dispatch<SetStateAction<number>>,
  onRedirect: () => void
) => {
  for (let i = 5; i > 0; i--) {
    setTimer(i);
    await new Promise((resolve) => {
      timerId.current = setTimeout(() => resolve(null), 1000);
    });
    if (i === 1) {
      onRedirect();
    }
  }
};

export const PasswordExpiredModal = ({
  visible = false,
  onRedirect,
  i18n,
}: IPasswordExpiredModalProps) => {
  const timerId = useRef<NodeJS.Timeout | null>(null);
  const [timer, setTimer] = useState(5);

  useEffect(() => {
    if (visible) {
      timerFunction(timerId, setTimer, onRedirect);
    }
    return () => {
      if (timerId.current) {
        clearTimeout(timerId.current);
        timerId.current = null;
        setTimer(5);
      }
    };
  }, [visible]);

  return (
    <Dialog
      open={visible}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{i18n.get("Password expired")}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ width: "100%", textAlign: "left" }}>
          {i18n.getByTemplate(
            "Password expired. You will be redirected to the page to reset your password in ### seconds, or",
            String(timer)
          ) + " "}
          <Link component="button" onClick={onRedirect} variant="body2">
            {i18n.get("click here")}
          </Link>
          {" " + i18n.get("to continue")}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
