import { RouteComponentProps } from "react-router-dom";
import { validate } from "class-validator";
import { HostedUIQueryParams } from "./hosted-ui-query-params";
import { Country } from "../interfaces/country";
import { Language } from "../interfaces/language";

export class HostedUIQueryValidator {
  // eslint-disable-next-line no-unused-vars
  constructor(private readonly props: RouteComponentProps) {}

  public async validateOrReject(): Promise<void> {
    const params = this.getParams();
    const errors = await validate(params);
    if (errors.length > 0) {
      const messages = [];
      for (const error of errors) {
        if (error.constraints !== undefined) {
          for (const key of Object.keys(error.constraints)) {
            messages.push(error.constraints[key]);
          }
        }
      }
      throw new Error(messages.join(", "));
    }
  }

  public getParams(): HostedUIQueryParams {
    const params = new HostedUIQueryParams();
    params.country = (this.getQueryParamByName("country") ?? "") as Country;
    params.company = this.getQueryParamByName("company") ?? "";
    params.language = (this.getQueryParamByName("language") ?? "") as Language;
    params.redirectUri = this.getQueryParamByName("redirect_uri") ?? "";
    params.clientId = this.getQueryParamByName("client_id") ?? "";
    params.username = this.getQueryParamByName("username") ?? "";
    params.session = this.getQueryParamByName("session") ?? "";
    params.destination = this.getQueryParamByName("destination") ?? "";
    params.previousPage = this.getQueryParamByName("previousPage") ?? "";

    return params;
  }

  private getQueryParamByName(name: string) {
    const searchInfo = this.props.location.search;
    const query = new URLSearchParams(searchInfo);
    return query.get(name);
  }
}
