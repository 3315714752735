import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { AuthenticationApi } from "./api/authentication-api";
import { environment } from "./config/environment";
import { dictionary } from "./I18n/dictionary";
import { I18n } from "./I18n/I18n";
import {
  ForgotPassword,
  ConfirmSignIn,
  SignIn,
  SignOut,
  ConfirmForgotPassword,
  Error,
  ChangePassword,
} from "./pages/";
import { FirstAccess } from "./pages/first-access";
import { CookiesService } from "./utils/cookie-service";
import { Provider, buildTheme } from "@naturacosmeticos/natds-web";
import { ThemeBrandName } from "@naturacosmeticos/natds-web/dist/Themes/ThemeBrandName";
import { ResendTemporaryPassword } from "./pages/resend-temporary-password";
import { RedirectToLogin } from "./pages/redirect-to-login";
import { FirstAccessDigitalStore } from "./pages/first-access-digital-store";

// eslint-disable-next-line
export default function App(props: any) {
  const company = getSelectedCompany();
  const theme = buildTheme(company, "light");

  const authProps = {
    api: new AuthenticationApi(environment.apiDomain, environment.apiToken),
    i18n: new I18n(dictionary),
    cookies: new CookiesService(),
  };

  return (
    <Provider theme={theme}>
      <Router basename={"/auth"}>
        <div>
          <Route
            exact
            path="/"
            render={(props) => <SignIn {...props} {...authProps} />}
          />
          <Route
            exact
            path="/confirm-sign-in"
            render={(props) => <ConfirmSignIn {...props} {...authProps} />}
          />
          <Route
            exact
            path="/forgot-password"
            render={(props) => <ForgotPassword {...props} {...authProps} />}
          />
          <Route
            exact
            path="/confirm-forgot-password"
            render={(props) => (
              <ConfirmForgotPassword {...props} {...authProps} />
            )}
          />
          <Route
            exact
            path="/first-access"
            render={(props) => <FirstAccess {...props} {...authProps} />}
          />
          <Route
            exact
            path="/change-password"
            render={(props) => <ChangePassword {...props} {...authProps} />}
          />
          <Route
            exact
            path="/resend-temporary-password"
            render={(props) => (
              <ResendTemporaryPassword {...props} {...authProps} />
            )}
          />
          <Route
            exact
            path="/first-access-digital-store"
            render={(props) => (
              <FirstAccessDigitalStore {...props} {...authProps} />
            )}
          />
          <Route
            exact
            path="/error"
            render={(props) => <Error {...props} {...authProps} />}
          />
          <Route
            exact
            path="/sign-out"
            render={(props) => <SignOut {...props} {...authProps} />}
          />
          <Route
            exact
            path="/redirect"
            render={(props) => <RedirectToLogin {...props} {...authProps} />}
          />
        </div>
      </Router>
    </Provider>
  );

  function getSelectedCompany(): ThemeBrandName {
    const query = new URLSearchParams(document.location.search);
    const company = query.get("company");
    if (company === "avon") {
      return "avon_v2";
    } else {
      return "natura";
    }
  }
}
