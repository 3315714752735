import { Language } from "../interfaces/language";

export interface I18nDictionary {
  [id: string]: string;
}

export interface I18nMultiLanguageDictionary {
  [id: string]: I18nDictionary;
}

export class I18n {
  private readonly multiLanguageDictionary: I18nMultiLanguageDictionary;

  private language: Language | undefined;

  public constructor(multiLanguageDictionary: I18nMultiLanguageDictionary) {
    this.multiLanguageDictionary = Object.assign({}, multiLanguageDictionary);
  }

  public setLanguage(language: Language): void {
    this.language = language;
  }

  public get(key: string, defaultValue: string | null = null): string {
    if (this.language === undefined) {
      return defaultValue === null ? key : defaultValue;
    }

    const language = this.language;
    const value = this.getByLanguage(language, key);
    if (value !== undefined && value !== null) {
      return value;
    }
    return defaultValue === null ? key : defaultValue;
  }

  public getByTemplate(
    key: string,
    parameter?: string,
    defaultValue: string | null = null
  ): string {
    const value = this.get(key, defaultValue);

    return parameter ? value.replace("###", parameter) : value;
  }

  public getByLanguage(
    language: Language,
    key: string,
    defaultValue: string | null = null
  ): string | null {
    const dictionary = this.multiLanguageDictionary[language];
    if (dictionary === undefined || dictionary === null) {
      return defaultValue;
    }
    return dictionary[key];
  }

  public putVocabulariesForLanguage(
    language: Language,
    vocabularies: I18nDictionary
  ): void {
    let dictionary = this.multiLanguageDictionary[language];
    if (dictionary === undefined || dictionary === null) {
      dictionary = this.multiLanguageDictionary[language] = {};
    }
    Object.assign(dictionary, vocabularies);
  }

  public putVocabularies(vocabularies: I18nMultiLanguageDictionary): void {
    for (const key of Object.keys(vocabularies)) {
      this.putVocabulariesForLanguage(key as Language, vocabularies[key]);
    }
  }
}
