// eslint-disable-next-line spellcheck/spell-checker
export const FlagChile = `
data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHYAAABPCAYAAAA+0jF0AAAABGdBTUEAALGPC/xhBQAA
ACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5
Q7t/AAAACXBIWXMAAAsSAAALEgHS3X78AAAAB3RJTUUH4gEaEyQJA8jKSQAABqNJREFUeNrtnV1v
W0kZgJ+ZM+f4K47dJA1ptyktSxu6Yhcoqy7iAiEE4gbtBSzS/kUuyh0SEhKCG7KIFEqWlm63hS1O
U8dJnMRf8ceZGS7GbiqapYljx83pPJIVxecjc/T4nXnnneMT8dOPf8ULBEABeAd4H/gucB04D+T7
2ydGo9llOp/io58t8csPl8jnUrTavUk26bTRQB3YBB4Cd4AV4D6w198OgPqfAxeA94Dv9X9+A1gE
cpO+Is9z5oB5YBqYAs7hgm4VeDrYaSBWADPAj4GfA7eAIhABctJX4nmJHK4nvYILwm8DvwZ+A2wD
diD2AvAj4CPgB7hPg+f1RgJpXC/7w/7vGvg9sK5wofwu8Avg+3ipZ5FpnLsYN/7uK1yi9AEupGcm
3ULP0MzgHP4NaChc9nsTNyB7zjbzOJe1gdglRpgkWQvWWhAghZj0xb5JSNxMpqE4mNKMDGMscWwQ
EkIVIKWT7TkVLgF7Che+I5mnDiI1lQooFlJobWnt99DaIqWP3FMiB8xJ3CR3JBhj0NowP5fl1ncu
cOP6LKEK6MVm0hf7ppGXjGhstdZiLQSBZPHiNB/cvMiNa7OEoST2Yk8bpU5y9EAmgNYGISXnCmm+
ujjN168UwcK5Yorq7j5xbAGLkAIBCCHwedX4UMCx0xohwBjoxQatLdZYOl1NLhtyfi7LpYU8xUKG
hfmYq4tFypUWO7v7GGv7yZQgCiVBIBHDNMDzSoaKWG0soZIU8hnCKMBoQ6drmCmm+ebSHIsXpwmV
ZHYmw833Fthvx3xR2kMbSyoK0NrQbPVotWOstQgfuiPn2GKttfR6hnwu4sa1WS5fmiaQAmNhppjm
2tvuPaUkhXzE+99aYPZchspWg15sEQIqWy3u/qPC4yc7aG0JlRc7ao4vlv7YiiWdVlxdLHL1coF0
JiRSkkIhjQqcKKUk83NZZoppevEcsbaUK01W7j5z5xoUMjwj59gZsUCgAkm7rXmyVmPtWZ1ASb4y
l2V2JvNc6osoJcmkQ7DwpFRj9Z+blCsNpIBA+lXBcXB8sQKCQBDHhvWNBndWyyzfWWdtvf5/j9vZ
bbO88pQ//Ok/fPaoSr3ZRQjhCxdjYqjkSQiBxdJuxzx4VGW/HZNLh1xcmEKplz8rFnj6rMEfl0vc
WS0TxweVKJ83jYeh+8FBJltvdCit19jYatLp6UP31dqyW2uzsdlkd6+DNsZnwmNm6AKFAIQUBP2X
CgRR6O51i7Wh1eoRBJJcNkQFgmwmJJNRbv4qfXFi3AwtdpDNRqmA4nSafD6Cfpb7+N+7rG80SEUB
b18pMj+XI5cNKU6nyGQUxrgVID++jo8TiAWLJT+V4q0LeaayEaX1GqWnNVb+Xqay3SJUAV+7XOSd
pVm0tkzlIoqFNHu1DlobXNx7xsGJxAJEYYAKJOXNJuXNJp/e3+T+59t0exosPHxc5Yu1XWZnMtQb
XaLQlRRjPexf9hyFocVKKbBApxNTWq9R3dun2zNUtlrUG13ALRBsVlvEDwxTuRBj3PqstZbAd8Nj
ZfjkSbhiRbujabWbmA2LwAnPZg5Oay3Um11qjY6bt/ZXdXxWPF5GU/ZxK3KHr9KIg+1uB19CPA1O
tB4LB5WoIDg8AgX0t/kIPU18oTaheLEJxYtNKOospTLPczCXVrs3fXZ9KKpBOOk2HJm6tUgielEa
shEAInXi/C+RqLdoTroNR6Yhe+SJydeqUN7GZiNMJ550s15L1Mfm0aTbcGRiZQi15Mq9Crp+j5YK
iLW/Z/kw1E/s2qTbcGREANaA/lcJ88jQxs+OvwylODufeIG7c8Mag4mNq1f65OlQ1P7Ji0+nT+he
XumX4+exCcWLTShebELxYhOKF5tQvNiE4sUmFC82oXixCcWLTShebELxYhOKF5tQvNiE4sUmFC82
oXixCcWLTShebELxYhOKF5tQvNiE4sUmFC82oXixCcWLTShebEKR+K/AJBHhIzahSDhD36P0HBUj
gcakW+EZOU0JVOAMPYjC8yqaQEUCnwFn53kFnlexBjyQwArwAD/WJgGDC9QVCfwF+CuuS/acbSo4
lysSuA/8GfgEqE66ZZ6hqeIcfgLck8AO8ClwG1gG6sOf2zMh6jh3t3EudwaPjHkG/Bb3/GID3AKK
QISvTL2uWKAL7OKG09s4h9tw8CBqC2wBv8P106vAu8B1YJER/Q93z8hoAiXgIS5Cl4G7OIfAy08Y
LwP7QAvXRdeBGnAeyAPBpK/oDUfjnGwCn+NkruDk7r64438Bqhw53DsikzkAAAAASUVORK5CYII=`;
