// eslint-disable-next-line no-unused-vars
import { IsNotEmpty, IsOptional } from "class-validator";
import { type Country } from "../interfaces/country";
import { type Language } from "../interfaces/language";

export class HostedUIQueryParams {
  @IsNotEmpty({
    message: "parameter 'country' is missing",
  })
  public country!: Country;

  @IsNotEmpty({
    message: "parameter 'company' is missing",
  })
  public company!: string;

  @IsNotEmpty({
    message: "parameter 'language' is missing",
  })
  public language!: Language;

  @IsNotEmpty({
    message: "parameter 'redirect_uri' is missing",
  })
  public redirectUri!: string;

  @IsNotEmpty({
    message: "parameter 'client_id' is missing",
  })
  public clientId!: string;

  @IsOptional()
  public session?: string;

  @IsOptional()
  public username?: string;

  @IsOptional()
  public destination?: string;

  @IsOptional()
  public previousPage?: string;
}
