import { Environment } from "../interfaces/environment";
import { getNewPassValidationConfig } from "./parse-password-config";

export const environment = {
  apiDomain: process.env.REACT_APP_API_DOMAIN as string,
  apiToken: process.env.REACT_APP_API_TOKEN as string,
  environment: process.env.REACT_APP_ENVIRONMENT as Environment,
  newPassValidationConfig: getNewPassValidationConfig(
    process.env.REACT_APP_NEW_PASS_VALIDATION_CONFIG
  ),
  forbiddenWords: JSON.parse(
    process.env.REACT_APP_FORBIDDEN_WORDS ?? "[]"
  ) as string[],
  minPasswordLength: Number(
    process.env.REACT_APP_MIN_PASSWORD_LENGTH as string
  ),
};
