/* eslint-disable spellcheck/spell-checker */
import React from "react";
import { styles } from "../styles/styles";
import { Logo } from ".";
import { Image } from "./image";
import {
  FlagArgentina,
  FlagBrasil,
  FlagChile,
  FlagColombia,
  FlagMalaysia,
  FlagPeru,
  FlagMexico,
} from "src/assets/country-flags";
import { Typography } from "@naturacosmeticos/natds-web";
import packageJson from "../../package.json";

interface ContainerProps {
  country?: string;
  company?: string;
}

export class Container extends React.Component<ContainerProps> {
  public constructor(props: ContainerProps) {
    super(props);
  }

  public render(): React.ReactNode {
    return (
      <div style={styles.containerView}>
        <div style={{ ...styles.container, textAlign: "center" }}>
          <Logo company={this.props?.company} />
          {this.props.children}
          {this.props.country && (
            <Image
              src={this.getCountryFlag(this.props.country)}
              alt=""
              maxWidth="semi"
            />
          )}
        </div>
        <div style={{ position: "absolute", bottom: -10, right: "10px" }}>
          <Typography color="textSecondary" variant="caption">
            V-{packageJson.version}
          </Typography>
        </div>
      </div>
    );
  }

  private getCountryFlag(country: string): string {
    switch (country.toLowerCase()) {
      case "my":
        return FlagMalaysia;
      case "mx":
        return FlagMexico;
      case "br":
        return FlagBrasil;
      case "co":
        return FlagColombia;
      case "cl":
        return FlagChile;
      case "pe":
        return FlagPeru;
      case "ar":
        return FlagArgentina;
      default:
        return "";
    }
  }
}
