import React from "react";
import styles from "../styles/styles";
import { Image, Logo as LogoNaturaDS } from "@naturacosmeticos/natds-web";
import avon from "./../assets/logo/logo-avon.png";
interface ILogoProps {
  company?: string;
}

export class Logo extends React.Component<ILogoProps> {
  constructor(props: ILogoProps) {
    super(props);
  }

  public render(): React.ReactNode {
    return (
      <div className="row" style={styles.logoView}>
        <div style={styles.logo}>
          {this.props.company === "avon" ? (
            <Image alt="avon logo" src={avon} style={styles.avonLogo} />
          ) : (
            <LogoNaturaDS />
          )}
        </div>
      </div>
    );
  }
}
