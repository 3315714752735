import React from "react";
import { Typography, Link as LinkNaturaDS } from "@naturacosmeticos/natds-web";

interface ILink {
  url: string;
  text: string;
}

export const Link = (props: ILink) => (
  <Typography paragraph>
    <LinkNaturaDS
      color="textPrimary"
      href={props.url}
      underline="none"
      variant="body2"
    >
      {props.text}
    </LinkNaturaDS>
  </Typography>
);
