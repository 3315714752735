import React, { ChangeEvent } from "react";
import { AuthPiece, IAuthPieceProps, IAuthPieceState } from "./auth-piece";
import { Button, Link, Input } from "../components";
import { Container } from "../components/container";
import { isNotEmpty } from "../utils/is-not-empty";
import { redirect } from "../utils/redirect";
import { decideQuerySeparator } from "../utils/query-builder";
import styles from "src/styles/styles";
import { FormHelperText, ProgressIndicator } from "@naturacosmeticos/natds-web";
import { State } from "@naturacosmeticos/natds-web/dist/Components/Input/Input.props";
import { isTooManyRequestsError } from "src/utils/too-many-requests-validator";
import { attemptLimitExceededMessage } from "src/utils/attempt-limit-exceeded-message";
import { ApiError } from "src/interfaces/api-error";
import { environment } from "src/config/environment";
import { SignInResponse } from "src/api/interfaces/sigin-response";
import { PasswordUpcomingExpirationModal } from "src/components/password-upcoming-expiration-modal";
import { PasswordExpiredModal } from "src/components/password-expired-modal";
import { getNewPassValidationConfigByCountry } from "src/config/parse-password-config";

export type ISignInProps = IAuthPieceProps;

export interface ISignInState extends IAuthPieceState {
  username?: string;
  password?: string;
  session?: string;
  usernameState?: string;
  usernameHelpText?: string;
  passwordState?: string;
  passwordHelpText?: string;
  formHelperState?: string;
  formHelperErrorMessage?: string;
  loading?: boolean;
  passwordExpired?: boolean;
  passwordWillExpireIn?: number;
  signInResult?: SignInResponse;
  passwordExpires?: boolean;
  expirationWarningTime?: number;
}

const FIELD_COULD_NOT_BE_EMPTY = "Field '###' could not be empty";

export class SignIn extends AuthPiece<ISignInProps, ISignInState> {
  private readonly refButton: React.RefObject<HTMLButtonElement>;

  public constructor(props: ISignInProps) {
    super(props);
    this.getUsernameFromInput = this.getUsernameFromInput.bind(this);
    this.getPasswordFromInput = this.getPasswordFromInput.bind(this);
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);

    this.refButton = React.createRef();
  }

  public async componentDidMount(): Promise<void> {
    await super.componentDidMount();
    await this.handleAlreadySignedInUsers();

    const countryConfig = getNewPassValidationConfigByCountry(
      environment.newPassValidationConfig,
      this.state?.country
    );
    const passwordExpires = countryConfig?.passwordExpires;
    const expirationWarningTime = passwordExpires
      ? countryConfig?.warningTime
      : undefined;

    this.setState({ passwordExpires, expirationWarningTime });
  }

  public getUsernameFromInput(): string | undefined {
    return this.state.username;
  }

  public getPasswordFromInput(): string | undefined {
    return this.state.password;
  }

  public render(): React.JSX.Element {
    const expirationWarningTime = this.state?.expirationWarningTime;
    const passwordWillExpireIn = this.state?.passwordWillExpireIn;
    if (!this.state?.loading) {
      return (
        <Container country={this.state?.country} company={this.state?.company}>
          <PasswordUpcomingExpirationModal
            visible={
              expirationWarningTime !== undefined &&
              passwordWillExpireIn !== undefined &&
              passwordWillExpireIn <= expirationWarningTime
            }
            onChoose={(resetPassword) =>
              this.onChoosePasswordModal(resetPassword as boolean)
            }
            i18n={this.props.i18n}
            daysToExpire={passwordWillExpireIn ?? 0}
          />
          <PasswordExpiredModal
            visible={this.state?.passwordExpires && this.state?.passwordExpired}
            onRedirect={() => this.navigate("change-password", this.state)}
            i18n={this.props.i18n}
          />
          <div className="row" style={styles.centerRow}>
            <div style={{ width: "300px", margin: "10px" }}>
              <Input
                id="username"
                type="text"
                value={this.state?.username}
                placeholder={this.props.i18n.get("username")}
                onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                  this.setState({
                    username: event.target.value,
                    usernameState: "",
                    usernameHelpText: "",
                  })
                }
                helpText={this.state?.usernameHelpText}
                state={this.state?.usernameState}
                onKeyDown={this.handleOnKeyDown}
                autoComplete="off"
              />
            </div>
          </div>

          <div className="row" style={styles.centerRow}>
            <div
              className="passwordInput"
              style={{ width: "300px", margin: "10px" }}
            >
              <Input
                id="password"
                type="password"
                placeholder={this.props.i18n.get("Password")}
                onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                  this.setState({
                    password: event.target.value,
                    passwordState: "", // pragma: allowlist secret
                    passwordHelpText: "", // pragma: allowlist secret
                  })
                }
                helpText={this.state?.passwordHelpText}
                state={this.state?.passwordState}
                onKeyDown={this.handleOnKeyDown}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="row" style={styles.centerRow}>
            <FormHelperText
              state={this.state?.formHelperState as State}
              style={styles.helperText}
            >
              {this.state?.formHelperErrorMessage}
            </FormHelperText>
          </div>
          <div className="row" style={styles.centerRow}>
            <div style={{ margin: "10px", width: "300px" }}>
              <Button
                id="signInButton"
                onClick={async (): Promise<void> => {
                  await this.onSignInButtonClick();
                }}
                text={this.props.i18n.get("login")}
                itemRef={this.refButton}
                keepEnabled={true}
              />
            </div>
          </div>
          <div style={{ marginTop: "10px" }}>
            <Link
              text={this.props.i18n.get("First access to the Digital Store")}
              url={this.buildNavigationLink("first-access-digital-store")}
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <Link
              text={this.props.i18n.get("Resend first access email")}
              url={this.buildNavigationLink("resend-temporary-password")}
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <Link
              text={this.props.i18n.get("Forgot password?")}
              url={this.buildNavigationLink("forgot-password")}
            />
          </div>
        </Container>
      );
    }
    return (
      <Container company={this.state?.company}>
        <ProgressIndicator size={64}></ProgressIndicator>
      </Container>
    );
  }

  private async onSignInButtonClick(): Promise<void> {
    try {
      if (!this.validUserInput()) {
        return;
      }

      this.setState({
        formHelperErrorMessage: "",
        formHelperState: "",
        loading: true,
      });

      const signInResult = await this.props.api.signIn({
        clientId: this.state?.clientId,
        country: this.state?.country as string,
        company: this.state?.company,
        username: this.state?.username?.replace(/ /g, "") as string,
        password: this.state?.password?.replace(/ /g, "") as string,
        redirectUrl: this.state.redirectUri,
      });
      const expirationWarningTime = this.state?.expirationWarningTime;
      if (
        expirationWarningTime !== undefined &&
        signInResult.daysExpirePassword <= expirationWarningTime
      ) {
        this.setState({
          loading: false,
          passwordWillExpireIn: signInResult.daysExpirePassword,
          signInResult,
        });
      } else {
        this.loginFlow(signInResult);
      }
    } catch (err) {
      const error = err as Error;
      this.setState({
        loading: false,
      });
      if (isTooManyRequestsError(error)) {
        this.showPasswordAttemptsExceededError();
      } else if (this.isNotFoundError(error)) {
        this.showNotFoundError();
      } else if (this.isInvalidPasswordError(error)) {
        this.showInvalidPasswordError();
      } else if (this.state?.passwordExpires && this.isPasswordExpired(error)) {
        this.setState({ passwordExpired: true });
      } else {
        this.showGenericError();
      }
    }
  }

  private async loginFlow(signInResult: SignInResponse): Promise<void> {
    if (signInResult.challengeRequired === true) {
      this.setState({ session: signInResult.challenge.session });
      this.navigate("first-access", this.state);
    } else {
      await this.saveUserSession(signInResult.sso_token);
      this.goBackToApplication(signInResult.sso_token);
    }
  }

  private async onChoosePasswordModal(resetPassword: boolean): Promise<void> {
    if (resetPassword) {
      this.navigate("change-password", this.state);
    } else {
      await this.loginFlow(this.state?.signInResult as SignInResponse);
    }
  }

  private async handleAlreadySignedInUsers(): Promise<void> {
    const cookieName = this.getCookieName();
    const ssoToken = this.props.cookies.getCookie(cookieName);
    try {
      if (ssoToken !== null && ssoToken !== undefined) {
        this.setState({
          loading: true,
        });
        await this.props.api.recoverSession(
          ssoToken,
          this.state.redirectUri,
          this.state.clientId
        );
        this.goBackToApplication(ssoToken);
      }
    } catch (error: any) {
      this.setState({
        loading: false,
      });
      if (
        !(error as Error).message.includes(
          "Invalid clientId and RedirectUrl combination"
        )
      ) {
        this.eraseUserSession();
      }
    }
  }

  private goBackToApplication(ssoToken: string): void {
    redirect(
      `${this.state.redirectUri}${decideQuerySeparator(
        this.state.redirectUri
      )}sso_token=${ssoToken}`
    );
  }

  private async saveUserSession(ssoToken: string): Promise<void> {
    const cookieName = this.getCookieName();
    this.props.cookies.createCookie(cookieName, ssoToken);
  }

  private eraseUserSession(): void {
    const cookieName = this.getCookieName();
    this.props.cookies.removeCookie(cookieName);
  }

  private getCookieName(): string {
    return `${this.state?.country}_${this.state?.company}_id`.toLowerCase();
  }

  private validUserInput(): boolean {
    let isUserInputValid = true;
    if (!isNotEmpty(this.state?.username)) {
      this.setUsernameError();
      isUserInputValid = false;
    }
    if (!isNotEmpty(this.state?.password)) {
      this.setPasswordError();
      isUserInputValid = false;
    }
    return isUserInputValid;
  }

  private setUsernameError() {
    this.setState({
      usernameState: "error",
      usernameHelpText: this.props.i18n.getByTemplate(
        FIELD_COULD_NOT_BE_EMPTY,
        this.props.i18n.get(this.props.i18n.get("username"))
      ),
    });
  }

  private setPasswordError() {
    this.setState({
      passwordState: "error", // pragma: allowlist secret
      passwordHelpText: this.props.i18n.getByTemplate(
        FIELD_COULD_NOT_BE_EMPTY,
        this.props.i18n.get("Password")
      ),
    });
  }

  private async handleOnKeyDown(
    event: React.KeyboardEvent<HTMLInputElement>
  ): Promise<void> {
    if (event.key.toLowerCase() === "enter") {
      await this.refButton.current?.click();
    }
  }

  private showGenericError() {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get(
        "We were unable to process your request. Please try again later"
      ),
      formHelperState: "error",
    });
  }

  private isInvalidPasswordError(error: Error): boolean {
    return (error as ApiError).message
      .toLowerCase()
      .includes("Incorrect username or password".toLowerCase());
  }

  private isPasswordExpired(error: Error): boolean {
    return (error as ApiError).message
      .toLowerCase()
      .includes("The password has expired".toLowerCase());
  }

  private showInvalidPasswordError() {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get("Invalid email or password"),
      formHelperState: "error",
    });
  }

  private isNotFoundError(error: Error): boolean {
    return (error as ApiError).status === 404;
  }

  private showNotFoundError() {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get(
        "Email does not exist, search for support"
      ),
      formHelperState: "error",
    });
  }

  private showPasswordAttemptsExceededError() {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get(
        attemptLimitExceededMessage()
      ),
      formHelperState: "error",
    });
  }
}
