import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { AuthenticationApi } from "../api/authentication-api";
import { I18n } from "../I18n/I18n";
import { Country } from "../interfaces/country";
import { Language } from "../interfaces/language";
import { CookiesService } from "../utils/cookie-service";
import { HostedUIQueryValidator } from "../utils/hosted-ui-query-validator";
import { buildQuery, decideQuerySeparator } from "../utils/query-builder";

export interface IAuthPieceProps extends RouteComponentProps {
  api: AuthenticationApi;
  i18n: I18n;
  cookies: CookiesService;
}

export interface IAuthPieceState {
  error?: string;
  clientId: string;
  country: Country;
  company: string;
  language: Language;
  redirectUri: string;
  username?: string;
  session?: string;
  destination?: string;
  previousPage?: string;
}
export abstract class AuthPiece<
  Props extends IAuthPieceProps,
  State extends IAuthPieceState
> extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
  }

  public async componentDidMount(): Promise<void> {
    this.setPageTile();

    try {
      const queryValidator = new HostedUIQueryValidator(this.props);
      await queryValidator.validateOrReject();
      const params = queryValidator.getParams();
      this.props.i18n.setLanguage(params.language);
      this.setState(params);
    } catch (error) {
      this.props.history.push("/error" + this.getCompanyParam(), error);
    }
  }

  private setPageTile() {
    const title = this.getPageTitle();
    document.title = this.props.i18n.get(title);
  }

  private getPageTitle(): string {
    return this.getCompany() === "natura"
      ? "Natura Authentication"
      : "Avon Authentication";
  }

  private getCompanyParam(): string {
    const company = this.getCompany() as string;
    return company ? "?company=" + company : "";
  }

  protected getCompany(): string | null {
    const query = new URLSearchParams(this.props.location.search);
    return query.get("company");
  }

  protected navigate(route: string, state?: unknown): void {
    this.props.history.replace(this.buildNavigationLink(route), state);
  }

  protected buildNavigationLink(route: string): string {
    return `/${route}${decideQuerySeparator(route)}${buildQuery(this.state)}`;
  }
}
