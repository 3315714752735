// eslint-disable-next-line spellcheck/spell-checker
export const FlagColombia = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHYAAABPCAMAAAAJDMFGAAAABGdBTUEAALGPC/xhBQAAACB
jSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABYlBMVEX2u0LtVWX2u0L2u0L2u0L2u0L2u0L2u0L2u0L2u0L2u0L2u0L2u0L2u0L2u
0L2u0L2u0L2u0L2u0L2u0L2u0L2u0L2u0L2u0L2u0L2u0L2u0L2u0L2u0L2u0L2u0L2u0L2u0L2u0L2u0L2u0L2u0L2u0L2u0L2u0L2u0L2u0L2u0L/wTz6vU
Cvk2xTX6I5ULFGWKlHWKk/WKx6V5TWVW76VWDwVWTtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWX
tVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWX2u0L/wTz6
vUCvk2xTX6I5ULFGWKlHWKk/WKx6V5TWVW76VWDwVWTtVWUAAACXHOObAAAAZ3RSTlMAAAMSPHCjyd7r7htJga/X9AETX7Hn9/0UGlyz8RFH7Tt+b7DYpMvh3
erv7u7u7u7u7u7u7u7u7u/t4eLL9Myk2HCw/bE7fud/PBFHrxpctPIBFGCy6Pj+sxtKhNdLAxJAc8ne63QTVi6E2gAAAAFiS0dEdahqmPsAAAAJcEhZcwAACx
IAAAsSAdLdfvwAAAAHdElNRQfiARoUFQZQZcbvAAABdklEQVRo3u3T2TqCcRSF8fXRpDlKlGSeJRkS8oVMIQqRIRH+5vn+H9GRC7C3g/3ewO9kLQCoqzcYTWZ
Lg/WPa7CYTUZDfR1q2ewOp8utCHK7nA677Qf1NDZ5fc1+ClUpf7PP29TiqbKtgWAbjVmrLRhoBULtzjClqlTY2R5CR6ePVlXK19mBrm4/NevvNsLkolaV6ulF
H8lzfuc2o59eVWoAxDOuNYghDtbKxVqFFVZYYYUVVlhhhRX2P7PDtwwNY+SOoRGM3jM0isgDQxGMPTI0hugTQ1GMPzM0LqywwgorrLCxF4ZiXOzEK0MTmHxja
BJT7wxNYfqDoWnEPxmKC0vBznCwM1xsgoNNYJaDncN8kl5NLkBP0bMpHYtLy9TqcnoRK6tr1Oza+gYym1vEY05sbWagbWd3dinV3Z3stgYtl9/bPygc0piHhY
OjvXyuympa8fgkfUryo+Rp+uSsWCW/2dJ5+UK/rFzF/7iryqV+Xb4pVckvxWfPi4kq2m0AAAAASUVORK5CYII=`;
