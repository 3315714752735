import { IAuthPieceState } from "../pages/auth-piece";
import { isNotEmpty } from "./is-not-empty";

export const decideQuerySeparator = (uri: string): string => {
  if (uri.includes("?")) {
    return "&";
  }
  return "?";
};

export const buildQuery = (state: IAuthPieceState) => {
  let query = `client_id=${state?.clientId}&country=${state?.country}&company=${state?.company}&language=${state?.language}&redirect_uri=${state?.redirectUri}`;

  if (isNotEmpty(state?.username)) {
    query = query.concat(`&username=${state.username}`);
  }
  if (isNotEmpty(state?.session)) {
    query = query.concat(`&session=${state.session}`);
  }
  if (isNotEmpty(state?.destination)) {
    query = query.concat(`&destination=${state.destination}`);
  }
  if (isNotEmpty(state?.previousPage)) {
    query = query.concat(`&previousPage=${state.previousPage}`);
  }
  return query;
};
