/* eslint-disable spellcheck/spell-checker */
import React, { ChangeEvent } from "react";
import { AuthPiece, IAuthPieceProps, IAuthPieceState } from "./auth-piece";
import { Button, Input } from "../components";
import { Container } from "../components/container";
import styles from "src/styles/styles";
export type IConfirmSignInProps = IAuthPieceProps;

export interface IConfirmSignInState extends IAuthPieceState {
  code?: string;
}

export class ConfirmSignIn extends AuthPiece<
  IConfirmSignInProps,
  IConfirmSignInState
> {
  public constructor(props: IConfirmSignInProps) {
    super(props);
    this.getCodeFromInput = this.getCodeFromInput.bind(this);
  }

  public getCodeFromInput(): string | undefined {
    return this.state.code;
  }

  public render(): React.ReactNode {
    return (
      <Container country={this.state?.country} company={this.state?.company}>
        <div className="row" style={styles.centerRow}>
          <div style={{ width: "300px", margin: "10px" }}>
            <Input
              type="text"
              placeholder={this.props.i18n.get("confirmationCode")}
              onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                this.setState({ code: event.target.value })
              }
            />
          </div>
        </div>
        <div className="row" style={styles.centerRow}>
          <div style={{ margin: "20px", width: "300px" }}>
            <Button
              onClick={(): any => {}}
              text={this.props.i18n.get("confirm")}
            />
          </div>
        </div>
      </Container>
    );
  }
}
