// eslint-disable-next-line spellcheck/spell-checker
export const FlagArgentina = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHYAAABPCAMAAAAJ
DMFGAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAACmlBM
VEVKidxKidxKidxKidxKidxKidxKidxKidxKidxKidxKidxKidxKidxKidxKidxKidxKidxKidxKidxKidxKidxKidx
KidxKidxKidxKidxKidxKidxKidxKidxKidxKidxKidxKidxKidxKidxKidxKidxKidxKidxKidxKidxDhdtAgtp4p+
TZ5fX///z4+fv19/pKidxKidxKidxKidxKidxKidxKidxKidxKidxKidxKidxKidxKidxKidxKidxKidxKidxDhdtAg
tp4p+TZ5fX///z4+fv4+vz4+v34+v/4+//19/r19/v1+P31+P/1+f/0+f/0+v/1+Pz1+P719/j28+v38N737tX47dL1
9/z19vX38Nz557j73pX82X791nb38N319vb29O3368v64aD913j+0Fr/y0b/yD3368z19fL46sb73Iz+0WD/zVH/z1b
/0Fv/0F71+f719/f37tf735b/ykX/z1j92oH64qb657n19/b28uT65LD+1XD/zUz+z1n824j56cH28+r65K/28uP47M
z82of824f56cL29O782ob47Mv557r902n/y0n+1G755rb457r55bL/yUD913n55K/+z1b92Hz479v1+//55bP+0F7/y
UL468v468z65bP46b/91XH/zE7+02n646j38eH646n56b/37tP73ZP/0V7/zlP65bD38eL65a/29Or56Lz92Hr/zU/9
13f65K7379n29e/65K3913b1+Pr28eL646b902f/y0j/zVD91XD83Iv74Jr19vf91nL/zlL/zE3/zEr28N355rX824v
902j83Iz557b28uj468r55K3735n73ZH28+j4+fr5+Pb59/D59u359ev///3////Z5fYAAABQzowqAAAAQXRSTlMAAx
I8cKPJ3uvuG0mBr9f0ARNfsef3/RQaXLPxEUftO35vsNiky+Hd6u/u7u7u7u7u4sx/tPJgsuj4/kqES0BzdI9SSooA
AAABYktHRN1wZ7MhAAAACXBIWXMAAAsSAAALEgHS3X78AAAAB3RJTUUH4gEaEyQJA8jKSQAAA0RJREFUaN5jYGBgYG
RiZmFlY+fgpDHgYGdjZWFmYmSAAC5uHl4+fkc6AH4+Xh5uLrClAoJCwiKiYvSw1dFRTFREWEhcAGithKSUNH3shABp
KUkJBgYZWV45etrq6CjHKyvDIK8gQl9bHR1FFOQZFJXoFK0IIKbEwsDKR29bHR2VVRhU6ZJzUAE/G4Ma/W11dFRnoH
MyhgANBs2BsJZzoKzlHLV21NpRa0etHbUW01otpwEAWgzazgMAtBl0XAYA6DDoug4A0GXQcxsAoMeg704O8PD08gYCL
08PsrTrMxj4kAF8/fwDAoOCAgP8g8nR7mNAjrW+ISGhYeERkZER4WGh/iG+dLI2KiQ6JjYuPiEhPi42MSkkij7Whvgl
p6SmpWdkZmakp6VmJfuF0MNa34DsnNy8/ILCoqLCgvy83JzsYpKDmXRrg31LSsvSyysqq6qrqyorytPLSmt8SU1YpFs
b4ltbV9/Q2NTcEhLS0tzU2FDf2uZLajCTbm2Ab3tHRXpnV7d/QIB/d1dnekVPr28AHazt658wcVKyv7+Pj79/8qSJE/
on08HaIJ8p6VOnRQb4A/NNlH9A5LSp6VOAgrS3dvqMzJmzZoOjM2T2rJmZBa10sXbO3HkJ84MDgPaGBAQvSJhXtJAO1
gb4Llq8ZOmy5VGguI1avmLpksUr6RC3xb6rVq9Zm7BuvV9QkN/6DQlr16xe5VtMc2v9fDdu2tywZeu27Tt2bN+5a0vD
5k0bff1obq2v7+49e/ftP3Dw0OHDhw4e2L9v757dvqSWjmSVyUdWpR5tPHb8xInjxxqPpq46EkCHMhlYKodEnzx1+kx
+Rkb+mbPnTkaHkF7Vk1ff+vuev3Dx0uXLly72XvH1J6u+Jb0tddXd09P92vUbN2/euH4NxL5KRluKrJbjrdtwcIu8li
OZ7eQ7UEBuO3mAegUD1AcaoB7fyOpWj1o7au2otaPWEmntgMyMaA6UtQMy6yU3MHN8hgMzo2nEoKJMf2uVVRhYBmC2
WphlQObmjZkHaiUCg4SkiSk9bTU1Aa27YBAQNzO3sLSij51WlhZSZuBVJgwMXNY2wvRaUyNsY8sFXcrDyGRnr0KfF
UQqDnaCoBVEALN/qlEuAIMsAAAAAElFTkSuQmCC`;
