import React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  Icon,
} from "@naturacosmeticos/natds-web";
import { Button } from "./button";
import { I18n } from "src/I18n/I18n";
import { environment } from "src/config/environment";

export interface IPasswordUpcomingExpirationModalProps {
  visible?: boolean;
  // eslint-disable-next-line no-unused-vars
  onChoose: (resetPassword?: boolean) => void;
  i18n: I18n;
  daysToExpire: number;
}

const sendButtonContainerStyle: React.CSSProperties = {
  width: "45%",
  padding: "0.5rem",
  display: "inline-flex",
  marginBottom: "1rem",
} as React.CSSProperties;

const sendButtonStyle: React.CSSProperties = {
  height: "4rem",
  wordBreak: "break-all",
};

export const PasswordUpcomingExpirationModal = ({
  visible = false,
  onChoose,
  i18n,
  daysToExpire,
}: IPasswordUpcomingExpirationModalProps) => {
  const forbiddenWords = environment.forbiddenWords;
  const minPasswordLength = environment.minPasswordLength;
  return (
    <Dialog
      open={visible}
      onClose={() => onChoose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        {i18n.getByTemplate(
          "Your password will expire in ### days. Do you want to exchange now?",
          String(daysToExpire)
        )}
      </DialogTitle>
      <DialogContent style={{ textAlign: "center", marginBottom: "15px" }}>
        <div style={{ display: "flex", width: "100%" }}>
          <Button
            containerStyle={sendButtonContainerStyle}
            style={sendButtonStyle}
            text={i18n.get("Yes")}
            variant="outlined"
            onClick={async () => onChoose(true)}
          />
          <Button
            containerStyle={sendButtonContainerStyle}
            style={sendButtonStyle}
            text={i18n.get("No")}
            variant="outlined"
            onClick={async () => onChoose(false)}
          />
        </div>
        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={
              <Icon name="outlined-navigation-arrowbottom" size="small" />
            }
            style={{ backgroundColor: "transparent" }}
          >
            <Typography variant="caption">
              <div>
                {i18n.get(
                  "Your new password must meet the following requirements"
                )}
                :
              </div>
            </Typography>
          </ExpansionPanelSummary>
          <DialogContentText style={{ marginLeft: "20px", textAlign: "left" }}>
            <Typography variant="caption">
              <div>{i18n.get("Minimum one number")}</div>
              <div>{i18n.get("Minimum one lowercase letter")}</div>
              <div>{i18n.get("Minimum one uppercase letter")}</div>
              <div>{i18n.get("Minimum one special character")}</div>
              <div>
                {i18n.getByTemplate(
                  "Minimum ### characters",
                  String(minPasswordLength)
                )}
              </div>
              <div>{`${i18n.get(
                "Without forbidden words"
              )} (${forbiddenWords.join(", ")})`}</div>
              <div>{i18n.get("Without sequences")}</div>
            </Typography>
          </DialogContentText>
        </ExpansionPanel>
      </DialogContent>
    </Dialog>
  );
};
