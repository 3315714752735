import React, { ChangeEvent } from "react";
import { AuthPiece, IAuthPieceProps, IAuthPieceState } from "./auth-piece";
import { Button, Input, Link } from "../components";
import { Container } from "../components/container";
import { isNotEmpty } from "../utils/is-not-empty";
import styles from "src/styles/styles";
import { FormHelperText, Typography } from "@naturacosmeticos/natds-web";
import { State } from "@naturacosmeticos/natds-web/dist/Components/Input/Input.props";
import { ApiError } from "src/interfaces/api-error";
import { isTooManyRequestsError } from "src/utils/too-many-requests-validator";
import { attemptLimitExceededMessage } from "src/utils/attempt-limit-exceeded-message";
import { environment } from "src/config/environment";
import { PasswordInput } from "src/components/password-input";
import { getStartDateByCountry } from "src/config/parse-password-config";

export type IConfirmForgotPasswordProps = IAuthPieceProps;

export interface IConfirmForgotPasswordState extends IAuthPieceState {
  username?: string;
  code?: string;
  newPassword?: string;
  confirmNewPassword?: string;
  destination?: string;
  codeState?: string;
  codeHelpText?: string;
  newPasswordState?: string;
  newPasswordHelpText?: string;
  confirmNewPasswordState?: string;
  confirmNewPasswordHelpText?: string;
  formHelperErrorMessage?: string;
  formHelperState?: string;
  anchorEl?: HTMLButtonElement;
  openPopover?: boolean;
  showForgotPasswordLink?: boolean;
}

const FIELD_COULD_NOT_BE_EMPTY = "Field '###' could not be empty";
const FIELD_CONFIRM_PASSWORD = "Confirm password"; // pragma: allowlist secret
const FIELD_NEW_PASSWORD = "New password"; // pragma: allowlist secret

export class ConfirmForgotPassword extends AuthPiece<
  IConfirmForgotPasswordProps,
  IConfirmForgotPasswordState
> {
  private readonly refButton: React.RefObject<HTMLButtonElement>;

  public constructor(props: IConfirmForgotPasswordProps) {
    super(props);
    this.getCodeFromInput = this.getCodeFromInput.bind(this);
    this.getPasswordFromInput = this.getPasswordFromInput.bind(this);
    this.getConfirmPasswordFromInput =
      this.getConfirmPasswordFromInput.bind(this);
    this.handleInfoButtonClick = this.handleInfoButtonClick.bind(this);
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
    this.isCodeFromQueryParam = this.isCodeFromQueryParam.bind(this);
    this.handleNewPasswordOnChange = this.handleNewPasswordOnChange.bind(this);
    this.handleConfirmNewPasswordOnChange =
      this.handleConfirmNewPasswordOnChange.bind(this);
    this.getPasswordInputs = this.getPasswordInputs.bind(this);

    this.refButton = React.createRef();

    this.setState({
      code: "code",
      showForgotPasswordLink: false,
    });
  }

  public getPasswordFromInput(): string | undefined {
    return this.state.newPassword;
  }

  public getConfirmPasswordFromInput(): string | undefined {
    return this.state.confirmNewPassword;
  }

  public getCodeFromInput(): string | undefined {
    return this.state.code;
  }

  private handleInfoButtonClick(
    event: React.MouseEvent<HTMLButtonElement>
  ): void {
    this.setState({
      openPopover:
        this.state?.anchorEl !== event.currentTarget
          ? true
          : !this.state?.openPopover,
      anchorEl: event.currentTarget,
    });
  }

  private getPasswordInputs(): React.JSX.Element {
    const startDate = getStartDateByCountry(
      environment.newPassValidationConfig,
      this.state?.country
    );
    if (startDate && new Date(startDate).getTime() <= Date.now()) {
      return (
        <>
          <PasswordInput
            style={styles.centerRow}
            i18n={this.props.i18n}
            placeholder={FIELD_NEW_PASSWORD}
            helpText={this.state?.newPasswordHelpText}
            value={this.state?.newPassword}
            state={this.state?.newPasswordState}
            onKeyDown={this.handleOnKeyDown}
            onChange={this.handleNewPasswordOnChange}
          />
          <div style={styles.centerRow}>
            <div className="natds-input-container passwordInput">
              <Input
                id="confirmNewPassword"
                type="password"
                placeholder={this.props.i18n.get(FIELD_CONFIRM_PASSWORD)}
                onChange={this.handleConfirmNewPasswordOnChange}
                state={this.state?.confirmNewPasswordState}
                helpText={this.state?.confirmNewPasswordHelpText}
                onKeyDown={this.handleOnKeyDown}
              />
            </div>
          </div>
        </>
      );
    }
    return (
      <>
        <div style={styles.centerRow}>
          <div className="natds-input-container passwordInput">
            <Input
              id="newPassword"
              type="password"
              placeholder={this.props.i18n.get(FIELD_NEW_PASSWORD)}
              onChange={this.handleNewPasswordOnChange}
              state={this.state?.newPasswordState}
              helpText={this.state?.newPasswordHelpText}
              onKeyDown={this.handleOnKeyDown}
            />
          </div>
        </div>
        <div style={styles.centerRow}>
          <div className="natds-input-container passwordInput">
            <Input
              id="confirmNewPassword"
              type="password"
              placeholder={this.props.i18n.get(FIELD_CONFIRM_PASSWORD)}
              onChange={this.handleConfirmNewPasswordOnChange}
              state={this.state?.confirmNewPasswordState}
              helpText={this.state?.confirmNewPasswordHelpText}
              onKeyDown={this.handleOnKeyDown}
            />
          </div>
        </div>
      </>
    );
  }

  public render(): React.JSX.Element {
    return (
      <Container country={this.state?.country} company={this.state?.company}>
        <div
          className="row"
          style={{ ...styles.centerRow, textAlign: "center" }}
        >
          <div className="natds-input-container">
            <Typography variant="caption">
              {`${this.props.i18n.get(
                `Instructions for creating a new password have been sent to`
              )}: ${this.state?.destination}`}
            </Typography>
          </div>
        </div>
        <div
          className="row"
          style={{
            ...styles.centerRow,
            overflow: "hidden",
            height: "0",
            background: "transparent",
          }}
        >
          <div className="natds-input-container">
            <input
              type="text"
              style={{
                height: 0,
                background: "transparent",
                color: "transparent",
                border: "none",
              }}
            />
            <input
              type="password"
              style={{
                height: 0,
                background: "transparent",
                color: "transparent",
                border: "none",
              }}
            />
          </div>
        </div>
        <div className="row" style={styles.centerRow}>
          <div className="natds-input-container">
            <Input
              id="code"
              type="text"
              placeholder={this.props.i18n.get("confirmationCode")}
              onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                this.setState({
                  code: event.target.value,
                  codeState: "",
                  codeHelpText: "",
                })
              }
              helpText={this.state?.codeHelpText}
              state={this.state?.codeState}
              onKeyDown={this.handleOnKeyDown}
            />
          </div>
        </div>

        {this.getPasswordInputs()}

        <div className="row" style={styles.centerRow}>
          <div className="natds-input-container" style={styles.centerRow}>
            <FormHelperText
              state={this.state?.formHelperState as State}
              style={styles.helperText}
            >
              {this.state?.formHelperErrorMessage}
            </FormHelperText>
          </div>
        </div>
        <div className="row" style={styles.centerRow}>
          {this.state?.showForgotPasswordLink && (
            <Link
              text={this.props.i18n.get("Forgot password?")}
              url={this.buildNavigationLink("forgot-password")}
            />
          )}
        </div>
        <div className="row" style={styles.centerRow}>
          <div className="natds-input-container">
            <Button
              id="confirmForgotPasswordButton"
              onClick={async (): Promise<void> => {
                await this.onButtonClick();
              }}
              text={this.props.i18n.get("confirm")}
              itemRef={this.refButton}
              disabled={
                !this.state?.code ||
                this.state?.codeState === "error" ||
                !this.state?.newPassword ||
                this.state?.newPasswordState === "error" || // pragma: allowlist secret
                !this.state?.confirmNewPassword ||
                this.state?.confirmNewPasswordState === "error" // pragma: allowlist secret
              }
            />
          </div>
        </div>
      </Container>
    );
  }

  private isCodeFromQueryParam(): boolean {
    const searchInfo = this.props.location.search;
    const query = new URLSearchParams(searchInfo);
    return !!query.get("code");
  }

  private async onButtonClick(): Promise<void> {
    try {
      this.setState({
        formHelperState: "",
        formHelperErrorMessage: "",
        showForgotPasswordLink: false,
      });
      if (!this.validateInputs()) {
        return;
      }

      await this.props.api.confirmForgotPassword({
        country: this.state?.country as string,
        company: this.state?.company,
        username: this.state?.username?.replace(/ /g, "") as string,
        code: this.state?.code?.replace(/ /g, "") as string,
        newPassword: this.state?.newPassword?.replace(/ /g, "") as string,
        clientId: this.state?.clientId,
      });
      this.setState({
        previousPage: "confirm-forgot-password",
      });
      this.navigate("redirect", this.state);
    } catch (error) {
      if (!this.handleAcceptableApiErrors(error)) {
        this.setState({
          formHelperErrorMessage: this.props.i18n.get(
            "The password cannot be changed, please communicate with customer support"
          ),
          formHelperState: "error",
        });
      }
    }
  }

  private validateInputs(): boolean {
    let isValid = true;
    if (!isNotEmpty(this.state?.code)) {
      this.setConfirmationCodeError();
      isValid = false;
    }
    if (!isNotEmpty(this.state?.newPassword)) {
      this.setNewPasswordError();
      isValid = false;
    }
    if (!isNotEmpty(this.state?.confirmNewPassword)) {
      this.setConfirmNewPasswordError();
      isValid = false;
    }
    if (this.state?.newPassword !== this.state?.confirmNewPassword) {
      this.setPasswordsMismatchError();
      isValid = false;
    }
    return isValid;
  }

  private handleNewPasswordOnChange(
    event: ChangeEvent<HTMLInputElement>,
    newPasswordState = "",
    newPasswordHelpText = ""
  ): void {
    const newPassword = event.target.value;

    this.setState({
      newPassword,
      newPasswordHelpText,
      newPasswordState,
    });
    this.validateConfirmNewPassword(
      newPassword,
      this.state.confirmNewPassword ?? ""
    );
  }

  private handleConfirmNewPasswordOnChange(
    event: ChangeEvent<HTMLInputElement>
  ): void {
    const confirmNewPassword = event.target.value;

    this.setState({
      confirmNewPassword,
    });
    this.validateConfirmNewPassword(
      this.state.newPassword ?? "",
      confirmNewPassword
    );
  }

  private validateConfirmNewPassword(
    newPassword: string,
    confirmNewPassword: string
  ): void {
    if (confirmNewPassword) {
      const confirmNewPasswordState =
        confirmNewPassword === newPassword ? "success" : "error";
      const confirmNewPasswordHelpText =
        confirmNewPasswordState === "error" // pragma: allowlist secret
          ? this.props.i18n.get("Passwords do not match")
          : "";

      this.setState({
        confirmNewPasswordState,
        confirmNewPasswordHelpText,
      });
    } else {
      this.setConfirmNewPasswordError();
    }
  }

  private setConfirmationCodeError() {
    this.setState({
      codeHelpText: this.props.i18n.getByTemplate(
        FIELD_COULD_NOT_BE_EMPTY,
        this.props.i18n.get("Confirmation code")
      ),
      codeState: "error",
    });
  }

  private setNewPasswordError() {
    this.setState({
      newPasswordHelpText: this.props.i18n.getByTemplate(
        FIELD_COULD_NOT_BE_EMPTY,
        this.props.i18n.get(FIELD_NEW_PASSWORD)
      ),
      newPasswordState: "error", // pragma: allowlist secret
    });
  }

  private setConfirmNewPasswordError() {
    this.setState({
      confirmNewPasswordHelpText: this.props.i18n.getByTemplate(
        FIELD_COULD_NOT_BE_EMPTY,
        this.props.i18n.get(FIELD_CONFIRM_PASSWORD)
      ),
      confirmNewPasswordState: "error", // pragma: allowlist secret
    });
  }

  private setPasswordsMismatchError() {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get("Passwords do not match"),
      formHelperState: "error",
    });
  }

  private async handleOnKeyDown(
    event: React.KeyboardEvent<HTMLInputElement>
  ): Promise<void> {
    if (event.key.toLowerCase() === "enter") {
      await this.refButton.current?.click();
    }
  }

  private handleAcceptableApiErrors(error: unknown): boolean {
    const apiError = error as ApiError;
    if (this.isExpiredTokenError(error)) {
      this.setState({
        formHelperErrorMessage: this.props.i18n.get(
          "Your token has expired, request a new password reset process by clicking the link below"
        ),
        formHelperState: "error",
        showForgotPasswordLink: true,
      });
      return true;
    } else if (this.isWrongTokenError(error)) {
      this.setState({
        formHelperErrorMessage: this.props.i18n.get(
          "Wrong token was entered, please check your email or request a new password reset by clicking the link below"
        ),
        formHelperState: "error",
        showForgotPasswordLink: true,
      });
      return true;
    } else if (this.isBadRequestError(error)) {
      this.setState({
        formHelperErrorMessage: this.props.i18n.get(apiError.message),
        formHelperState: "error",
      });
      return true;
    } else if (isTooManyRequestsError(error)) {
      this.showPasswordAttemptsExceededError();
      return true;
    }

    return false;
  }

  private showPasswordAttemptsExceededError() {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get(
        attemptLimitExceededMessage()
      ),
      formHelperState: "error",
    });
  }

  private isExpiredTokenError(error: unknown) {
    return (error as ApiError).message.includes("Your token has expired");
  }

  private isWrongTokenError(error: unknown) {
    return (error as ApiError).message.includes("Wrong token was entered");
  }

  private isBadRequestError(error: unknown) {
    return (error as ApiError).status === 400;
  }

  private showBadRequestError(error: unknown) {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get((error as ApiError).message),
      formHelperState: "error",
    });
  }
}
