/* eslint-disable spellcheck/spell-checker */
import { I18nMultiLanguageDictionary } from "./I18n";

export const dictionary: I18nMultiLanguageDictionary = {
  pt: {
    username: "Email",
    confirmationCode: "Token de confirmação",
    name: "nome",
    country: "país",
    "sign up": "cadastrar",
    login: "acessar",
    Password: "Senha", //pragma: allowlist secret
    confirm: "confirmar",
    Send: "Enviar",
    Cancel: "Cancelar",
    Understood: "Entendido",
    Yes: "Sim",
    No: "Não",
    "Current password": "Senha atual", //pragma: allowlist secret
    "New password": "Nova senha", //pragma: allowlist secret
    "Confirmation code": "Código de confirmação",
    "Confirm password": "Confirmar senha", //pragma: allowlist secret
    "Confirm new password": "Confirmar senha", //pragma: allowlist secret
    "Forgot password?": "Esqueceu a senha?", //pragma: allowlist secret
    "Resend first access email": "Reenviar e-mail de primeiro acesso",
    "Field '###' could not be empty": "O campo '###' deve ser preenchido",
    "Avon Authentication": "Autenticação Avon",
    "parameter 'country' is missing":
      "o parametro 'country' deve ser informado",
    "parameter 'company' is missing":
      "o parametro 'company' deve ser informado",
    "parameter 'language' is missing":
      "o parametro 'language' deve ser informado",
    "parameter 'redirectUri' is missing":
      "o parametro 'redirectUri' deve ser informado",
    "An error was encountered with the requested page":
      "Um erro foi encontrado na página solicitada",
    "Invalid email or password": "Email ou senha inválidos", //pragma: allowlist secret
    "username options must contain at least these options:":
      "o username deve conter, pelo menos, as opções a seguir:",
    "not acceptable username type":
      "username não aceito dentro das configurações estabelecidas",
    "We were unable to process your request. Please try again later":
      "Não foi possível processar sua solicitação. Por favor, tente novamente mais tarde",
    "username not found": "usuário não encontrado",
    "passwords do not match": "as senhas devem ser iguais", //pragma: allowlist secret
    "create new password": "criar nova senha", //pragma: allowlist secret
    "User does not have an email address, please contact the service center.":
      "O usuário não possui um e-mail, por favor entre em contato com a central de atendimento.",
    "Instructions for creating a new password have been sent to":
      "As instruções para criar uma nova senha foram enviadas para ",
    "Enter your registered e-mail address and receive instructions to recover your password. If you do not have an email, contact customer service":
      "Digite seu endereço de e-mail registrado e receba instruções para recuperar sua senha. Se você não tiver um e-mail, entre em contato com o atendimento ao cliente.",
    "Enter your registered email and receive instructions to recover your password.":
      "Digite seu email registrado e receba instruções para recuperar sua senha.",
    "If you do not have an email, contact customer service":
      "Se você não tiver um e-mail, entre em contato com o atendimento ao cliente.",
    "Invalid confirmation code or password":
      "Código de confirmação ou senha inválida", //pragma: allowlist secret
    "Passwords do not match": "As senhas não coincidem", //pragma: allowlist secret
    "Fill in the fields below to create a new password":
      "Preencha os campos a seguir, para criar uma nova senha", //pragma: allowlist secret
    "The password must be at least 6 characters long, and contain at least one number":
      "A senha deve ter pelo menos 6 caracteres, e possuir pelo menos um número", //pragma: allowlist secret
    "Failed to set new password": "Falha ao definir a nova senha", //pragma: allowlist secret
    "User blocked for over-trying, wait a few minutes and try again":
      "Usuário bloqueado por excesso de tentativas, aguarde alguns minutos e tente novamente", //pragma: allowlist secret
    "Enter your registered email and receive your temporary password. If you do not have an email, contact customer service":
      "Digite seu email e receba sua senha temporária. Se você não tiver um e-mail, entre em contato com o atendimento ao cliente.",
    "The password cannot be changed, please communicate with customer support":
      "A senha não pode ser alterada, por favor comunique-se com a atenção",
    "Empty email":
      "O usuário não possui um endereço de e-mail válido, por favor, comunique-se com atenção para atualizar seus dados",
    "Your token has expired, request a new password reset process by clicking the link below":
      "Seu token expirou, solicite um novo processo de redefinição de senha clicando no link abaixo",
    "Wrong token was entered, please check your email or request a new password reset by clicking the link below":
      "O token incorreto foi inserido, verifique seu e-mail ou solicite uma nova redefinição de senha clicando no link abaixo",
    "You already have a validated access, if you have difficulties to enter, request a new password by clicking on the link below":
      "Você já tem um acesso validado, se tiver dificuldades para entrar, solicite uma nova senha clicando no link abaixo",
    "Go back to Login": "Voltar para o Login",
    "Your password was updated with success!":
      "Sua senha foi atualizada com sucesso!",
    "please click in the button below to go back to login page":
      "Por favor clique no botão abaixo para voltar à página de login",
    "An e-mail was sent with your temporary password, please check your email an go back to login page by clicking in the button below":
      "Foi enviado um e-mail com sua senha temporária, verifique seu e-mail e volte para a página de login clicando no botão abaixo",
    "You still not make your first access, check your email to see if you already have an temporary password, or request a new one on the link below":
      "Você ainda não fez seu primeiro acesso, verifique seu e-mail para ver se já possui uma senha temporária ou solicite uma nova no link abaixo",
    "Your password has been successfully updated, please return to the NaturaOn app":
      "Sua senha foi atualizada com sucesso, por favor retorne ao aplicativo NaturaOn",
    "User does not exists": "Usuário não existe",
    "First access to the Digital Store": "Primeiro acesso à Digital Store",
    "Email does not exist, search for support":
      "Email não existente, procure pelo atendimento",
    "Enter your registered e-mail address and receive instructions to recover your password.":
      "Digite seu e-mail cadastrado para receber uma senha temporária.",
    "You can now go back to the login page":
      "Agora você pode voltar para a página de login",
    "Minimum one number": "Mínimo um número",
    "Minimum one lowercase letter": "Mínimo de uma letra minúscula",
    "Minimum one uppercase letter": "Mínimo de uma letra maiúscula",
    "Minimum one special character": "Mínimo de um caractere especial",
    "Minimum ### characters": "Mínimo de ### caracteres",
    "Without forbidden words": "Sem palavras proibidas",
    "Without sequences": "Sem sequências",
    "The password does not meet all requirements":
      "A senha não atende a todos os requisitos",
    "Your password will expire in ### days. Do you want to exchange now?":
      "Sua senha expirará em ### dias. Deseja trocar agora?",
    "Your new password must meet the following requirements":
      "Sua nova senha deve atender aos seguintes requisitos",
    "Password expired": "Senha expirada",
    "Password expired. You will be redirected to the page to reset your password in ### seconds, or":
      "A senha expirou. Você será redirecionado para a página para redefinir sua senha em ### segundos, ou",
    "click here": "clique aqui",
    "to continue": "para continuar",
    "The password is not valid": "A senha não é válida",
  },
  en: {
    username: "Email",
    confirmationCode: "Confirmation Token",
    "Empty email":
      "The user does not have a valid email address, please communicate carefully to update your data",
  },
  es: {
    username: "Correo electrónico",
    confirmationCode: "Código de confirmación",
    name: "nombre",
    country: "país",
    "sign up": "inscribirse",
    login: "acceso",
    Password: "Contraseña", //pragma: allowlist secret
    confirm: "confirmar",
    Send: "Enviar",
    Cancel: "Anular",
    Understood: "Entendido",
    Yes: "Sí",
    No: "No",
    "Current password": "Contraseña actual", //pragma: allowlist secret
    "New password": "Contraseña nueva", //pragma: allowlist secret
    "Confirmation code": "Código de confirmacion",
    "Confirm password": "Confirmar contraseña", //pragma: allowlist secret
    "Confirm new password": "Confirmar contraseña", //pragma: allowlist secret
    "Forgot password?": "¿Has olvidado tu contraseña?", //pragma: allowlist secret
    "Resend first access email":
      "Reenviar mail de primer acceso a Sitio Virtual",
    "Field '###' could not be empty": "El campo '###' no puede estar vacío",
    "Avon Authentication": "Autenticación Avon",
    "parameter 'country' is missing": "falta el parámetro 'country'",
    "parameter 'company' is missing": "falta el parámetro 'company'",
    "parameter 'language' is missing": "falta el parámetro 'language'",
    "parameter 'redirectUri' is missing": "falta el parámetro 'redirectUri'",
    "An error was encountered with the requested page":
      "Se encontró un error con la página solicitada",
    "Invalid email or password": "Correo eletrónico o contraseña inválido", //pragma: allowlist secret
    "username options must contain at least these options:":
      "Las opciones de nombre de usuario deben contener al menos estas opciones:",
    "not acceptable username type": "tipo de nombre de usuario no aceptable",
    "We were unable to process your request. Please try again later":
      "No pudimos procesar su solicitud. Por favor, inténtelo de nuevo más tarde",
    "username not found": "usuario no encontrado",
    "passwords do not match": "la contraseña y su confirmación no coinciden", //pragma: allowlist secret
    "create new password": "crear nueva contraseña", //pragma: allowlist secret
    "User does not have an email address, please contact the service center.":
      "El usuario no tiene una dirección de correo electrónico, comuníquese con el centro de servicio.",
    "Instructions for creating a new password have been sent to":
      "Se han enviado instrucciones para crear una nueva contraseña a",
    "Enter your registered e-mail address and receive instructions to recover your password. If you do not have an email, contact customer service":
      "Ingrese su dirección de correo electrónico registrada y reciba instrucciones para recuperar su contraseña. Si no tiene un correo electrónico, comuníquese con la attencion al cliente",
    "Enter your registered email and receive instructions to recover your password.":
      "Ingresa tu correo electrónico registrado para recibir instrucciones para recuperar tu contraseña.",
    "If you do not have an email, contact customer service":
      "Si no tienes correo electrónico o tienes alguna duda, comunícate al 55 1000 2866",
    "Invalid confirmation code or password":
      "Código de confirmación o contraseña no válidos", //pragma: allowlist secret
    "Passwords do not match": "Las contraseñas no coinciden", //pragma: allowlist secret
    "Fill in the fields below to create a new password":
      "Complete los campos a continuación para crear una nueva contraseña", //pragma: allowlist secret
    "The password must be at least 6 characters long, and contain at least one number":
      "La contraseña debe tener al menos 6 caracteres y contener al menos 1 número.", //pragma: allowlist secret
    "Failed to set new password": "No se pudo establecer una nueva contraseña", //pragma: allowlist secret
    "User blocked for over-trying, wait a few minutes and try again":
      "Usuario bloqueado por exceso de intentos, espere unos minutos y vuelva a intentarlo", //pragma: allowlist secret
    "Enter your registered email and receive your temporary password. If you do not have an email, contact customer service":
      "Ingrese su correo eletrónico registrado y reciba su contraseña temporal. Si no tiene un correo electrónico, comuníquese con la attencion al cliente.",
    "The password cannot be changed, please communicate with customer support":
      "No se puede cambiar la contraseña, favor comunicarse con atención",
    "Empty email":
      "El usuario no tiene una dirección de correo electrónico válida, favor comunicarse com atencion para actualizar su datos",
    "Your token has expired, request a new password reset process by clicking the link below":
      "Su token ha caducado, solicite un nuevo proceso de restablecimiento de contraseña haciendo clic en el enlace de abajo",
    "Wrong token was entered, please check your email or request a new password reset by clicking the link below":
      "Se ingresó un token incorrecto, verifique su correo electrónico o solicite un nuevo restablecimiento de contraseña haciendo clic en el enlace de abajo",
    "You already have a validated access, if you have difficulties to enter, request a new password by clicking on the link below":
      "Ya tiene un acceso validado, si tiene dificultades para ingresar, solicite una nueva contraseña haciendo clic en el enlace de abajo",
    "Go back to Login": "Volver a Iniciar sesión",
    "Your password was updated with success!":
      "¡Tu contraseña se actualizó con éxito!",
    "please click in the button below to go back to login page":
      "Da click en el botón de abajo para volver a la página de inicio de sesión",
    "An e-mail was sent with your temporary password, please check your email an go back to login page by clicking in the button below":
      "Se envió un correo electrónico con su contraseña temporal, verifique su correo electrónico y vuelva a la página de inicio de sesión haciendo clic en el botón de abajo",
    "You still not make your first access, check your email to see if you already have an temporary password, or request a new one on the link below":
      "Aún no realizas tu primer acceso, revisa tu correo electrónico para ver si ya tienes una contraseña temporal, o solicita una nueva en el siguiente enlace",
    "Your password has been successfully updated, please return to the NaturaOn app":
      "Su contraseña se ha actualizado correctamente, vuelva a la aplicación NaturaOn",
    "User does not exists": "El usuario no existe",
    "First access to the Digital Store": "Primer acceso a Sitio Virtual",
    "Email does not exist, search for support":
      "El correo electrónico no existe, busque soporte",
    "Enter your registered e-mail address and receive instructions to recover your password.":
      "Ingresa tu correo electrónico registrado para recibir una contraseña temporal.",
    "You can now go back to the login page":
      "Ahora puede volver a la página de inicio de sesión",
    "Minimum one number": "Mínimo un número",
    "Minimum one lowercase letter": "Mínimo una letra minúscula",
    "Minimum one uppercase letter": "Mínimo una letra mayúscula",
    "Minimum one special character": "Mínimo un carácter especial",
    "Minimum ### characters": "Mínimo ### caracteres",
    "Without forbidden words": "Sin palabras prohibidas",
    "Without sequences": "Sin secuencias",
    "The password does not meet all requirements":
      "La contraseña no cumple con todos los requisitos",
    "Your password will expire in ### days. Do you want to exchange now?":
      "Su contraseña caducará en ### días. ¿Quieres cambiar ahora?",
    "Your new password must meet the following requirements":
      "Su nueva contraseña debe cumplir con los siguientes requisitos",
    "Password expired": "Contraseña caducada",
    "Password expired. You will be redirected to the page to reset your password in ### seconds, or":
      "La contraseña expiró. Será redirigido a la página para restablecer su contraseña en ### segundos, o",
    "click here": "clic aquí",
    "to continue": "para continuar",
    "The password is not valid": "La contraseña no es válida",
  },
};
