/* eslint-disable spellcheck/spell-checker */
export const styles = {
  logo: {
    fontSize: 50,
    color: "#fb5b5a",
    marginBottom: 20,
    marginTop: 90,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logoView: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonView: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  errorPageView: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  containerView: {
    display: "flex",
    alignItems: "end",
    justifyContent: "center",
  },
  container: {
    flex: 1,
    display: "list-item",
    backgroundColor: "#F8F8F8",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "50%",
  },
  centerRow: {
    justifyContent: "center",
    display: "flex",
  },
  helperText: {
    fontSize: "16px",
    width: "300px",
    margin: "10px",
  },
  avonLogo: {
    width: "120px",
  },
};

export default styles;
