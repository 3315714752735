// eslint-disable-next-line spellcheck/spell-checker
export const FlagPeru = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHYAAABPCAMAAAAJDMFGAAAABGdBTUEAALGPC/xhBQAAACBjSF
JNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAA9lBMVEXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtTV3tTl/wjJf05en2///
1+fz19/r19vnxqrPtWWnsSVrtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXt
VWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtVWXtTV3tTl/wjJf05en2///1
+fz19/r19vnxqrPtWWnsSVoAAAAIpfFWAAAARXRSTlMAAxI8cKPJ3uvu7u7u7u7u7u7u7u4bSYGv1/QBE1+x5/f9FBpcs/ERR+07fm+w2KTL4d3q7+LMf7Ty
YLLo+P5KhEtAc3QvwODFAAAAAWJLR0RRlGl8KgAAAAlwSFlzAAALEgAACxIB0t1+/AAAAAd0SU1FB+IBGhQVBlBlxu8AAAFJSURBVGje7drJVsJAEAXQyghx
wDGQARJCEiAgiQMqICCi7YDj/3+NgG5Du2i7N/XW75y7qV09AABJVlRNLxSNvGxt7+yW9vZpKR0cHh0b+SkWdE1VZAl+YpYrlu2Q/Dw+Pb8sXt9oWbx/fH6
RTXFsq1I216hbrXl+PSA8WBLUfa/WcJdsGMXNzV2G7DLNOAoBWm0roTWZsiSx2i3odH1qkS1L/G4HTnoBbzboqaDZ9B5jlqQZnDr0GmvW0eHsDzXWLDkH6hn
/B3sBfRGsIYo1kEUWWWSRRRZZZJFFFllkkUUWWWSRRRZZZJFFFllk+bNCPiN9UayQr1ci5sd3KeajeQVZyp9NM1AFfKs9Vchv/loRtUSAMBoMebLDwWp3AW5
jdDOeTPmw08k4Hq1XJgDm7czjtanxZnfm75RHkuf3GZ8FUfYwr64WRN9fb8V6GBy3FwAAAABJRU5ErkJggg==`;
