import { PasswordValidationData } from "src/interfaces/validate-password-data";

const getNextCharacter = (
  character: string,
  variation: number
): string | undefined => {
  const newCharacterCode = character.charCodeAt(0) + variation;
  if (
    // 0-9
    (newCharacterCode >= 48 && newCharacterCode <= 57) ||
    // a-z
    (newCharacterCode >= 97 && newCharacterCode <= 122)
  ) {
    return String.fromCharCode(newCharacterCode);
  }
  return undefined;
};

const testSequence = (pass: string): boolean => {
  if (pass.length < 3) {
    return false;
  }
  const passLowerCase = pass.toLowerCase();
  for (let i = 0; i < passLowerCase.length - 2; i++) {
    // Tests for repetitions
    if (
      passLowerCase[i] === passLowerCase[i + 1] &&
      passLowerCase[i + 1] === passLowerCase[i + 2]
    ) {
      return true;
    }
    // Tests for increasing sequences
    if (
      passLowerCase[i + 1] === getNextCharacter(passLowerCase[i], 1) &&
      passLowerCase[i + 2] === getNextCharacter(passLowerCase[i], 2)
    ) {
      return true;
    }
    // Tests for descending sequences
    if (
      passLowerCase[i + 1] === getNextCharacter(passLowerCase[i], -1) &&
      passLowerCase[i + 2] === getNextCharacter(passLowerCase[i], -2)
    ) {
      return true;
    }
  }
  return false;
};

export const getInvalidPasswordValidationData = (): PasswordValidationData =>
  ({
    isValid: false,
    isLong: false,
    hasNoSequence: false,
    hasNumber: false,
    hasLowerCase: false,
    hasUpperCase: false,
    hasSpecialCharacter: false,
    hasNoForbiddenWords: false,
  } as PasswordValidationData);

export const passwordValidator = (
  pass: string,
  forbiddenWords: string[],
  minPasswordLength: number
): PasswordValidationData => {
  if (pass === "") {
    return getInvalidPasswordValidationData();
  }
  const data = new PasswordValidationData();
  if (pass.length < minPasswordLength) {
    data.isLong = false;
    data.isValid = false;
  }
  if (testSequence(pass.toLowerCase())) {
    data.hasNoSequence = false;
    data.isValid = false;
  }
  if (!/^(?=.*\d).*$/g.test(pass)) {
    data.hasNumber = false;
    data.isValid = false;
  }
  if (!/^(?=.*[a-z]).*$/g.test(pass)) {
    data.hasLowerCase = false;
    data.isValid = false;
  }
  if (!/^(?=.*[A-Z]).*$/g.test(pass)) {
    data.hasUpperCase = false;
    data.isValid = false;
  }
  if (!/^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹]).*$/g.test(pass)) {
    data.hasSpecialCharacter = false;
    data.isValid = false;
  }
  for (const word of forbiddenWords) {
    if (pass.toLowerCase().includes(word.toLowerCase())) {
      data.hasNoForbiddenWords = false;
      data.isValid = false;
      break;
    }
  }
  return data;
};
