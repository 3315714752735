/* eslint-disable spellcheck/spell-checker */
import React, { ChangeEvent } from "react";
import { AuthPiece, IAuthPieceProps, IAuthPieceState } from "./auth-piece";
import { Button, Input, Link } from "../components";
import { Container } from "../components/container";
import styles from "src/styles/styles";
import { FormHelperText, Typography } from "@naturacosmeticos/natds-web";
import { isNotEmpty } from "class-validator";
import { State } from "@naturacosmeticos/natds-web/dist/Components/Input/Input.props";
import { ApiError } from "src/interfaces/api-error";

export type IResendTemporaryPasswordProps = IAuthPieceProps;

export interface IResendTemporaryPasswordState extends IAuthPieceState {
  username?: string;
  usernameState?: string;
  usernameHelpText?: string;
  formHelperState?: string;
  formHelperErrorMessage?: string;
  showForgotPasswordLink: boolean;
}

const FIELD_COULD_NOT_BE_EMPTY = "Field '###' could not be empty";

export class ResendTemporaryPassword extends AuthPiece<
  IResendTemporaryPasswordProps,
  IResendTemporaryPasswordState
> {
  private readonly refButton: React.RefObject<HTMLButtonElement>;

  public constructor(props: IResendTemporaryPasswordProps) {
    super(props);
    this.getUsernameFromInput = this.getUsernameFromInput.bind(this);
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);

    this.refButton = React.createRef();

    this.setState({
      username: "",
      usernameState: "",
      usernameHelpText: "",
      formHelperState: "",
      formHelperErrorMessage: "",
      showForgotPasswordLink: false,
    });
  }

  public getUsernameFromInput(): string | undefined {
    return this.state.username;
  }

  public render(): React.ReactNode {
    return (
      <Container country={this.state?.country} company={this.state?.company}>
        <div className="row" style={{ ...styles.centerRow, textAlign: "left" }}>
          <div style={{ width: "300px", margin: "10px" }}>
            <Typography variant="caption">
              {this.props.i18n.get(
                `Enter your registered email and receive your temporary password. If you do not have an email, contact customer service`
              )}
            </Typography>
          </div>
        </div>
        <div className="row" style={styles.centerRow}>
          <div style={{ width: "300px", margin: "10px" }}>
            <Input
              id="username"
              type="text"
              placeholder={this.props.i18n.get("username")}
              onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                this.setState({
                  username: event.target.value,
                  usernameState: "",
                  usernameHelpText: "",
                })
              }
              state={this.state?.usernameState}
              helpText={this.state?.usernameHelpText}
              onKeyDown={this.handleOnKeyDown}
              value={this.state?.username}
            />
          </div>
        </div>
        <div className="row" style={styles.centerRow}>
          <FormHelperText
            state={this.state?.formHelperState as State}
            style={styles.helperText}
          >
            {this.state?.formHelperErrorMessage}
          </FormHelperText>
        </div>
        <div className="row" style={styles.centerRow}>
          {this.state?.showForgotPasswordLink && (
            <Link
              text={this.props.i18n.get("Forgot password?")}
              url={this.buildNavigationLink("forgot-password")}
            />
          )}
        </div>
        <div className="row" style={styles.centerRow}>
          <div style={{ margin: "20px", width: "300px" }}>
            <Button
              id="resendTemporaryPasswordButton"
              onClick={async (): Promise<void> => {
                await this.onButtonClick();
              }}
              text={this.props.i18n.get("Send")}
              itemRef={this.refButton}
            />
          </div>
        </div>
      </Container>
    );
  }

  private async onButtonClick(): Promise<void> {
    try {
      this.setState({
        formHelperErrorMessage: "",
        formHelperState: "",
        showForgotPasswordLink: false,
      });

      if (!this.validUserInput()) {
        return;
      }

      await this.props.api.resendTemporaryPassword({
        country: this.state?.country as string,
        company: this.state?.company,
        username: this.state?.username?.replace(/ /g, "") as string,
        clientId: this.state?.clientId,
      });
      this.setState({
        previousPage: "resend-temporary-password",
      });
      this.navigate("redirect", this.state);
    } catch (error) {
      const apiError = error as ApiError;
      if (
        apiError.message.includes(
          "User status does not require a resend temporary password"
        )
      ) {
        this.setState({
          formHelperErrorMessage: this.props.i18n.get(
            "You already have a validated access, if you have difficulties to enter, request a new password by clicking on the link below"
          ),
          formHelperState: "error",
          showForgotPasswordLink: true,
        });
      } else if ((error as ApiError).status === 404) {
        this.setState({
          formHelperErrorMessage: this.props.i18n.get(
            "Email does not exist, search for support"
          ),
          formHelperState: "error",
        });
      } else {
        this.setState({
          formHelperErrorMessage: this.props.i18n.get(
            "We were unable to process your request. Please try again later"
          ),
          formHelperState: "error",
        });
      }
    }
  }

  private validUserInput(): boolean {
    let isUserInputValid = true;
    if (!isNotEmpty(this.state?.username)) {
      this.setUsernameError();
      isUserInputValid = false;
    }
    return isUserInputValid;
  }

  private setUsernameError() {
    this.setState({
      usernameHelpText: this.props.i18n.getByTemplate(
        FIELD_COULD_NOT_BE_EMPTY,
        this.props.i18n.get(this.props.i18n.get("username"))
      ),
      usernameState: "error",
    });
  }

  private async handleOnKeyDown(
    event: React.KeyboardEvent<HTMLInputElement>
  ): Promise<void> {
    if (event.key.toLowerCase() === "enter") {
      await this.refButton.current?.click();
    }
  }
}
