/* eslint-disable spellcheck/spell-checker */
import React, { ChangeEvent } from "react";
import { AuthPiece, IAuthPieceProps, IAuthPieceState } from "./auth-piece";
import { Button, Input, Link } from "../components";
import { Container } from "../components/container";
import styles from "src/styles/styles";
import { FormHelperText, Typography } from "@naturacosmeticos/natds-web";
import { isNotEmpty } from "class-validator";
import { State } from "@naturacosmeticos/natds-web/dist/Components/Input/Input.props";
import { ForgotPasswordBody } from "src/api/interfaces/forgot-password-body";
import { FetchUserInfoResponse } from "src/api/interfaces/fetch-user-info-response";
import { attemptLimitExceededMessage } from "src/utils/attempt-limit-exceeded-message";
import { isTooManyRequestsError } from "src/utils/too-many-requests-validator";
import { ApiError } from "src/interfaces/api-error";

export type IForgotPasswordProps = IAuthPieceProps;

export interface IForgotPasswordState extends IAuthPieceState {
  username?: string;
  usernameState?: string;
  usernameHelpText?: string;
  formHelperState?: string;
  formHelperErrorMessage?: string;
  showResendTemporaryPasswordLink?: boolean;
}

const FIELD_COULD_NOT_BE_EMPTY = "Field '###' could not be empty";

export class ForgotPassword extends AuthPiece<
  IForgotPasswordProps,
  IForgotPasswordState
> {
  private readonly refButton: React.RefObject<HTMLButtonElement>;

  public constructor(props: IForgotPasswordProps) {
    super(props);
    this.getUsernameFromInput = this.getUsernameFromInput.bind(this);
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);

    this.refButton = React.createRef();

    this.setState({
      username: "",
      usernameState: "",
      usernameHelpText: "",
      formHelperState: "",
      formHelperErrorMessage: "",
      showResendTemporaryPasswordLink: false,
    });
  }

  public getUsernameFromInput(): string | undefined {
    return this.state.username;
  }

  public render(): React.ReactNode {
    return (
      <Container country={this.state?.country} company={this.state?.company}>
        <div
          className="row"
          style={{ ...styles.centerRow, textAlign: "center" }}
        >
          <div style={{ width: "300px", margin: "10px" }}>
            <Typography variant="caption">
              {this.state?.country === "my"
                ? this.props.i18n.get(
                    `Enter your registered e-mail address and receive instructions to recover your password.`
                  )
                : this.props.i18n.get(
                    `Enter your registered email and receive instructions to recover your password.`
                  )}
              <br />
              {this.props.i18n.get(
                `If you do not have an email, contact customer service`
              )}
            </Typography>
          </div>
        </div>
        <div className="row" style={styles.centerRow}>
          <div style={{ width: "300px", margin: "10px" }}>
            <Input
              id="username"
              type="text"
              placeholder={this.props.i18n.get("username")}
              onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                this.setState({
                  username: event.target.value,
                  usernameState: "",
                  usernameHelpText: "",
                })
              }
              state={this.state?.usernameState}
              helpText={this.state?.usernameHelpText}
              onKeyDown={this.handleOnKeyDown}
              value={this.state?.username}
            />
          </div>
        </div>
        <div className="row" style={styles.centerRow}>
          <FormHelperText
            state={this.state?.formHelperState as State}
            style={styles.helperText}
          >
            {this.state?.formHelperErrorMessage}
          </FormHelperText>
        </div>
        <div className="row" style={styles.centerRow}>
          {this.state?.showResendTemporaryPasswordLink && (
            <Link
              text={this.props.i18n.get("Resend first access email")}
              url={this.buildNavigationLink("resend-temporary-password")}
            />
          )}
        </div>
        <div className="row" style={styles.centerRow}>
          <div style={{ margin: "20px", width: "300px" }}>
            <Button
              id="forgotPasswordButton"
              onClick={async (): Promise<void> => {
                await this.onButtonClick();
              }}
              text={this.props.i18n.get("Send")}
              itemRef={this.refButton}
            />
          </div>
        </div>
      </Container>
    );
  }

  private async onButtonClick(): Promise<void> {
    try {
      this.setState({
        formHelperErrorMessage: "",
        formHelperState: "",
        showResendTemporaryPasswordLink: false,
      });

      if (!this.validUserInput()) {
        return;
      }

      const userInfo = await this.tryFetchUserInfo();

      if (userInfo.status === "FORCE_CHANGE_PASSWORD") {
        this.showUserNotConfirmedError();
        this.setState({
          showResendTemporaryPasswordLink: true,
        });
      } else {
        const result = await this.props.api.forgotPassword({
          country: this.state?.country as string,
          company: this.state?.company,
          username: this.state?.username?.replace(/ /g, "") as string,
          clientId: this.state?.clientId,
          redirectUrl: this.state?.redirectUri,
          state: this.buildForgotPasswordUrlState(),
        } as ForgotPasswordBody);

        if (result.wasForcedCreated) {
          this.navigate("");
        } else {
          this.setState({
            destination: result.destination,
          });
          this.navigate("confirm-forgot-password", {
            username: this.state.username,
          });
        }
      }
    } catch (err) {
      const error = err as Error;
      if (isTooManyRequestsError(err)) {
        this.showPasswordAttemptsExceededError();
      } else if (this.isEmailEmptyError(error)) {
        this.showEmptyEmailError();
      } else if (this.isNotFoundError(error)) {
        this.showNotFoundError();
      } else {
        this.showGenericError();
      }
    }
  }

  private buildForgotPasswordUrlState(): string {
    return `client_id=${this.state.clientId}&country=${this.state.country}&company=${this.state.company}&language=${this.state.language}&redirect_uri=${this.state.redirectUri}&username=${this.state.username}`;
  }

  private async tryFetchUserInfo(): Promise<FetchUserInfoResponse> {
    try {
      return await this.props.api.fetchUserInfo({
        country: this.state?.country as string,
        company: this.state?.company,
        username: this.state?.username as string,
        clientId: this.state?.clientId,
      });
    } catch (error) {
      return {
        status: "NOT_FOUND",
      } as FetchUserInfoResponse;
    }
  }

  private validUserInput(): boolean {
    let isUserInputValid = true;
    if (!isNotEmpty(this.state?.username)) {
      this.setUsernameError();
      isUserInputValid = false;
    }
    return isUserInputValid;
  }

  private setUsernameError() {
    this.setState({
      usernameHelpText: this.props.i18n.getByTemplate(
        FIELD_COULD_NOT_BE_EMPTY,
        this.props.i18n.get(this.props.i18n.get("username"))
      ),
      usernameState: "error",
    });
  }

  private async handleOnKeyDown(
    event: React.KeyboardEvent<HTMLInputElement>
  ): Promise<void> {
    if (event.key.toLowerCase() === "enter") {
      await this.refButton.current?.click();
    }
  }

  private isEmailEmptyError(error: Error): boolean {
    return error.message.toLowerCase().includes("email is empty");
  }

  private isNotFoundError(error: Error): boolean {
    return (error as ApiError).status === 404;
  }

  private showPasswordAttemptsExceededError() {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get(
        attemptLimitExceededMessage()
      ),
      formHelperState: "error",
    });
  }

  private showGenericError() {
    this.showError(
      "We were unable to process your request. Please try again later"
    );
  }

  private showEmptyEmailError() {
    this.showError("Empty email");
  }

  private showUserNotConfirmedError() {
    this.showError(
      "You still not make your first access, check your email to see if you already have an temporary password, or request a new one on the link below"
    );
  }

  private showNotFoundError() {
    this.showError("Email does not exist, search for support");
  }

  private showError(message: string) {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get(message),
      formHelperState: "error",
    });
  }
}
