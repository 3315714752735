import { countries } from "./country-list";

interface ICountryConfigObject {
  validationStartDate?: string;
  passwordExpires?: boolean;
  expirationTime?: number;
  firstExpirationTime?: number;
  warningTime?: number;
}

type NewPassValidationCountryValue = ICountryConfigObject | string | undefined;

interface INewPassValidationConfigObject {
  [index: string]: NewPassValidationCountryValue;
  ar?: NewPassValidationCountryValue;
  mx?: NewPassValidationCountryValue;
}

export interface ICountryValidationConfig {
  validationStartDate: Date;
  passwordExpires: boolean;
  expirationTime?: number;
  firstExpirationTime?: number;
  warningTime?: number;
}

export interface IValidationConfig {
  validationStartDate: Date;
  passwordExpires: boolean;
  expirationTime?: number;
  firstExpirationTime?: number;
  warningTime?: number;
}

const parseNewPassValidationConfig = (
  config: string
): INewPassValidationConfigObject =>
  JSON.parse(config) as INewPassValidationConfigObject;

export const parseNewPassValidationCountryConfig = (
  value: NewPassValidationCountryValue
): ICountryValidationConfig | undefined => {
  switch (typeof value) {
    case "object":
      return value.validationStartDate !== undefined
        ? {
            ...value,
            passwordExpires: value.passwordExpires === true,
            validationStartDate: new Date(value.validationStartDate),
          }
        : undefined;
    case "string":
      return {
        validationStartDate: new Date(value),
        passwordExpires: false,
      };
    default:
      return undefined;
  }
};

export interface INewPassValidationConfig {
  [index: string]: ICountryValidationConfig | undefined;
  ar?: ICountryValidationConfig;
  mx?: ICountryValidationConfig;
}

export const getNewPassValidationConfig = (
  config?: string
): INewPassValidationConfig => {
  const result: INewPassValidationConfig = {};
  const parsedConfig = parseNewPassValidationConfig(config || "{}");
  for (const country of countries) {
    const countryConfig = parsedConfig[country];
    const parsedCountryConfig =
      parseNewPassValidationCountryConfig(countryConfig);
    if (parsedCountryConfig !== undefined) {
      result[country] = parsedCountryConfig;
    }
  }
  return result;
};

export const getNewPassValidationConfigByCountry = (
  config?: INewPassValidationConfig,
  country?: string
): ICountryValidationConfig | undefined => {
  return country !== undefined ? config?.[country.toLowerCase()] : undefined;
};

export const getStartDateByCountry = (
  config?: INewPassValidationConfig,
  country?: string
): Date | undefined => {
  const parsedConfig = getNewPassValidationConfigByCountry(config, country);
  return parsedConfig?.validationStartDate;
};
