import React from "react";
import { AuthPiece, IAuthPieceProps, IAuthPieceState } from "./auth-piece";
import { Container } from "../components/container";

export type ISignOutProps = IAuthPieceProps;

export interface ISignOutState extends IAuthPieceState {
  sso_token?: string;
}

export class SignOut extends AuthPiece<ISignOutProps, ISignOutState> {
  public constructor(props: ISignOutProps) {
    super(props);
  }

  public async componentDidMount(): Promise<void> {
    await super.componentDidMount();
    await this.handleSignOutUser();
  }

  public render(): React.ReactNode {
    return <Container company={this.state?.company}></Container>;
  }

  private async handleSignOutUser(): Promise<void> {
    const cookieName = this.getCookieName();
    const ssoToken = this.props.cookies.getCookie(cookieName);
    try {
      await this.props.api.signOut(ssoToken);
      this.goBackToLogin();
    } catch {
      this.eraseUserSession();
    }
  }

  private goBackToLogin(): void {
    this.navigate("");
  }

  private eraseUserSession(): void {
    const cookieName = this.getCookieName();
    this.props.cookies.removeCookie(cookieName);
  }

  private getCookieName(): string {
    return `${this.state?.country}_${this.state?.company}_id`;
  }
}
